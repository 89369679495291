import React, { Fragment, useState, useEffect } from "react";
import {
  Avatar,
  Form,
  Input,
  Button,
  Spin,
  Select,
  DatePicker,
  message,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
const { Option } = Select;

const AddPartner = ({
  onGetPartnerDetail,
  partnerDetail,
  onUpdateClientProfileMember,
  onUpdateClientEmail,
  onClientProfileSaveAddress,
  onUpdateClientPhone,
  canUpdate,
  setUpdateVal,
  getAllCountriesRes,
  onGetVisaTypes,
  clientProfile,
  onAddClientMember,
  setPartner,
  memberType,
  onGetFamilyMemberDetail,
  familyMemberSection,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showPartnerForm, setShowPartnerForm] = useState(true);
  const [img, setImg] = useState(null);
  const [editorState, setEditorState] = useState("");
  const [outputHTML, setOutputHTML] = useState("<p></p>");
  const [selectedContry, setSelectedContry] = useState([]);
  const [visaTypes, setVisaTypes] = useState([]);
  // const [isAdd, setAdd] = useState(false);
  useEffect(() => {
    onGetVisaTypes().then((res) => {
      let { items } = res.payload;
      setVisaTypes(items);
    });
  }, []);
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    setLoading(true);
    let {
      id,
      clientNumber,
      branchId,
      familyId,
      agentId,
      currentNewZealandVisaExpiry,
    } = JSON.parse(localStorage.getItem("userDetail"));

    const data = {
      branchId: branchId,
      clientNumber: clientNumber,
      familyId: familyId,
      processingGroupId: 0,
      agentId: agentId,
      clientTag: 0,
      firstName: values && values.first_name,
      lastName: values && values.last_name,
      middleName:
        values && values.middle_name ? values && values.middle_name : "",
      title: "",
      gender: 0,
      dateOfBirth: "1900-01-01T00:00:00+00:00",
      maritalStatus: "",
      dependentChildren: 0,
      notes: outputHTML,
      occupation: "",
      occupationOrganization: "",
      inzUserName: "",
      inzPassword: "",
      imageBlobUrl: "",
      nationalityId: "",
      nationalityCountry: "",
      skypeID: "",
      preferredName: "",
      isSubscribed: true,
      arbitaryJson: "",
      dependentClientIds: "=",
      modifiedBy: id,
      currentVisaTypeId: values.current_visa_type,
      currentNewZealandVisaExpiry: values.current_new_zeland_visa_expiry_date
        ? values.current_new_zeland_visa_expiry_date.toISOString()
        : currentNewZealandVisaExpiry,
      travelConditionsValidTo: "1900-01-01T00:00:00+00:00",
      visaText: "",
      visaDenied: true,
      deniedText: "",
      clientNumberIZM: "",
      inzFeeDate: "1900-01-01T00:00:00+00:00",
      memberType: memberType,
      clientId: id,
      nzqaOnlineSubDate: "1900-01-01T00:00:00+00:00",
      nzqaDocumentSubDate: "1900-01-01T00:00:00+00:00",
      nzqaDocumentRetDate: "1900-01-01T00:00:00+00:00",
      visaCountryId: 0,
      visaCountyType: 0,
      age: 0,
      jobSectorId: 0,
      sourceId: 0,
      sourceDescription: "",
      clientSerial: "",
      companyOptional: "",
      dealWorth: "",
      saleDate: "1900-01-01T00:00:00+00:00",
      clientPermission: {
        signed: false,
        onshore: false,
        active: true,
        allowUpdate: false,
        areaAccess: false,
      },
      clientMedical: {
        clientId: id,
        er: "",
        medicalIssueDate: "1900-01-01T00:00:00+00:00",
        medicalExpiryDate: "1900-01-01T00:00:00+00:00",
        xrayIssueDate: "1900-01-01T00:00:00+00:00",
        xrayExpiryDate: "1900-01-01T00:00:00+00:00",
        medicalGrading: "",
        xrayGrading: "",
        medicalNotes: "",
        medicalNotesDetail: "",
        createdBy: id,
      },
      clientEmails: [
        {
          clientId: id,
          address: values.email ? values.email : "",
          emailTypeId: 1,
          createdBy: id,
        },
      ],
      addresses: [
        {
          clientId: id,
          contactPerson: "",
          flat: "",
          building: "",
          streetName: values.address ? values.address : "",
          suburb: "",
          streetNumber: "",
          city: values.address ? values.address : "",
          state: "",
          zip: "",
          country: 0,
          addressTypeId: 1,
          modifiedBy: id,
        },
      ],
      clientPhones: [],
      passports: [
        {
          clientId: id,
          passportNo: values.passport_number ? values.passport_number : "",
          passportCountry:
            values && values.country_of_issue ? values.country_of_issue : 0,
          passportIssueDate: values.passport_issue_date
            ? values.passport_issue_date.toISOString()
            : "1900-01-01T00:00:00+00:00",
          passportExpiryDate: values.passport_expiry_date
            ? values.passport_expiry_date.toISOString()
            : "1900-01-01T00:00:00+00:00",
          modifiedBy: id,
          passportType: 1,
        },
      ],
      policeCertificates: [],
    };
    if (values.mobile_phone && values.mobile_phone.length)
      data.clientPhones.push({
        id: 0,
        clientId: id,
        countryCodeId: 168,
        contact: values.mobile_phone ? values.mobile_phone : "",
        phoneTypeId: 1,
        modifiedBy: id,
      });
    if (values.overseas_mobile && values.overseas_mobile.length)
      data.clientPhones.push({
        id: 0,
        clientId: id,
        countryCodeId: 168,
        contact: values.secondary_mobile ? values.secondary_mobile : "",
        phoneTypeId: 2,
        modifiedBy: id,
      });
    if (values.overseas_mobile && values.overseas_mobile.length)
      data.clientPhones.push({
        id: 0,
        clientId: id,
        countryCodeId: 168,
        contact: values.overseas_mobile ? values.overseas_mobile : "",
        phoneTypeId: 3,
        modifiedBy: id,
      });
    if (values.land_line && values.land_line.length)
      data.clientPhones.push({
        id: 0,
        clientId: id,
        countryCodeId: 168,
        contact: values.land_line ? values.land_line : "",
        phoneTypeId: 4,
        modifiedBy: id,
      });
    if (
      values.other_contact_information &&
      values.other_contact_information.length
    )
      data.clientPhones.push({
        id: 0,
        clientId: id,
        countryCodeId: 168,
        contact: values.other_contact_information
          ? values.other_contact_information
          : "",
        phoneTypeId: 5,
        modifiedBy: id,
      });
    onAddClientMember(data)
      .then(() => {
        onGetFamilyMemberDetail()
          .then(() => {
            message.success("Family member added Successfully!");
            setLoading(false);
            familyMemberSection("");
          })
          .catch(() => {
            message.error("Could't add family member!");
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleChange = (editorState) => {
    setEditorState(editorState);
    setOutputHTML(editorState.toHTML());
  };
  const handleChangeCountry = (value) => {
    setSelectedContry(value);
  };

  console.log("partnerDetailpartnerDetail", partnerDetail);
  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form
          name="register"
          onFinish={onFinish}
          scrollToFirstError
          form={form}
        >
          <div className="container client-info-tab information-area-bg padding-tb">
            <div className="client-information-container">
              <div className="align-center mb-30">
                <h2 className="info-heading">{t("PERSONAL INFORMATION")}</h2>
              </div>
              <div className="align-center">
                <Avatar
                  src={
                    "https://www.shareicon.net/data/2016/05/26/771187_man_512x512.png"
                  }
                  size={95}
                  icon={<UserOutlined />}
                />
              </div>
              <div className="information-area">
                <ul>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Client's First Name")}:</span>
                    <div className="w40-40">
                      <Form.Item
                        className="profile-form"
                        name="first_name"
                        rules={[
                          {
                            required: false,
                            message: "Please input your first name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Middle Name")}:</span>
                    <div className="w40-40">
                      {!canUpdate && (
                        <span>{partnerDetail && partnerDetail.middleName}</span>
                      )}
                      <Form.Item
                        className="profile-form"
                        name="middle_name"
                        rules={[
                          {
                            required: false,
                            message: "Please input your middle name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Last Name")}:</span>
                    <div className="w40-40">
                      <span>{partnerDetail && partnerDetail.lastName}</span>
                      <Form.Item
                        className="profile-form"
                        name="last_name"
                        rules={[
                          {
                            required: false,
                            message: "Please input your last name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Email")}:</span>
                    <div className="w40-40">
                      <Form.Item
                        className="profile-form"
                        name="email"
                        rules={[
                          {
                            required: false,
                            message: "Please input your email!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Address")}:</span>
                    <div className="w40-40">
                      <Form.Item
                        className="profile-form"
                        name="address"
                        rules={[
                          {
                            required: false,
                            message: "Please input your title!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Mobile Phone")}:</span>
                    <div className="w40-40">
                      <Form.Item
                        className="profile-form"
                        name="mobile_phone"
                        rules={[
                          {
                            required: false,
                            message: "Please input your country!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </li>

                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Overseas Mobile")}</span>
                    <div className="w40-40">
                      <Form.Item
                        className="profile-form"
                        name="overseas_mobile"
                        rules={[
                          {
                            required: false,
                            message: "Please input your overseas mobile!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Land Line")}:</span>
                    <div className="w40-40">
                      <Form.Item
                        className="profile-form"
                        name="land_line"
                        rules={[
                          {
                            required: false,
                            message: "Please input your land line!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">
                      {t("Other Contact Information")}:
                    </span>
                    <div className="w40-40">
                      <Form.Item
                        className="profile-form"
                        name="other_contact_information"
                        rules={[
                          {
                            required: false,
                            message:
                              "Please input your other contact information!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Notes")}:</span>
                    <div className="w40-40">
                      <Form.Item
                        className="profile-form"
                        name="notes"
                        rules={[
                          {
                            required: false,
                            message: "Please input your notes!",
                          },
                        ]}
                      >
                        <div className="editor-wrapper">
                          <BraftEditor
                            value={editorState}
                            onChange={handleChange}
                          />
                        </div>
                      </Form.Item>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container client-info-tab information-area-bg mar-top padding-tb">
            <div className="client-information-container">
              <div className="align-center">
                <h2 className="info-heading">{t("PASSPORT DETAIL")}</h2>
              </div>

              <div className="information-area">
                <ul>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Passport Number")}:</span>
                    <div className="w40-40">
                      <Form.Item
                        className="profile-form"
                        name="passport_number"
                        rules={[
                          {
                            required: false,
                            message: "Please input your passport number!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Country Of Issue")}:</span>
                    <div className="w40-40">
                      <Form.Item
                        className="profile-form"
                        name="country_of_issue"
                        rules={[
                          {
                            required: false,
                            message: "Please input your select gender!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select"
                          value={selectedContry}
                          onChange={handleChangeCountry}
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          getPopupContainer={(trigger) => trigger.parentElement}
                        >
                          {getAllCountriesRes &&
                            getAllCountriesRes.items &&
                            getAllCountriesRes.items.length > 0 &&
                            getAllCountriesRes.items.map((item, index) => (
                              <Option key={index} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Passport Issue Date")}:</span>
                    <div className="w40-40">
                      <Form.Item
                        name="passport_issue_date"
                        className="profile-form"
                      >
                        <DatePicker format={"DD/MM/YYYY"} />
                      </Form.Item>
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">
                      {t("Passport Expiry Date")}:
                    </span>
                    <div className="w40-40">
                      <Form.Item
                        name="passport_expiry_date"
                        className="profile-form"
                      >
                        <DatePicker format={"DD/MM/YYYY"} />
                      </Form.Item>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container client-info-tab information-area-bg mar-top padding-tb">
            <div className="client-information-container">
              <div className="align-center">
                <h1 className="info-heading">{t("VISA DETAIL (IF KNOWN)")}</h1>
              </div>
              <div className="information-area">
                <ul>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Current Visa Type")}:</span>
                    <div className="w40-40">
                      <Form.Item
                        className="profile-form"
                        name="current_visa_type"
                        rules={[
                          {
                            required: false,
                            message: "Please input your current visa type!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select"
                          value={selectedContry}
                          onChange={handleChangeCountry}
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          getPopupContainer={(trigger) => trigger.parentElement}
                        >
                          {visaTypes &&
                            visaTypes.map((item, index) => (
                              <Option key={index} value={item.id}>
                                {item.visaTypeName}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">
                      {t("Current New Zeland Visa Expiry")} <br /> {t("Date")}:
                    </span>
                    <div className="w40-40">
                      <Form.Item
                        name="current_new_zeland_visa_expiry_date"
                        className="profile-form"
                      >
                        <DatePicker format={"DD/MM/YYYY"} />
                      </Form.Item>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mar-top d-flex flex-end submit-access-btn">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button mr-24"
            >
              {t("Submit")}
            </Button>
          </div>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default AddPartner;

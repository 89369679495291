import React, { useEffect, useState } from "react";

import { Table, Space, Spin, Badge, message } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { getUrlLastId } from "./../../Common/reUseFunctions";
import { Button } from "antd";
// import { saveAs } from "file-saver";

import DocumentUploadButton from "./DocumentUploadButton";

const DocumentFormOne = ({
  // onGetDocumentData,
  // getDocumentRes,

  // onGetDownlaodDocument,
  // downloadDocumentRes,

  documentLinkRes,
  onGetDocumentLink,

  onAddDocument,
  onGetClientProfile,

  onAddDocumentItem,

  location,
  onAddMultiDocument,
  onAddDocumentChecklist,
}) => {
  const [loading, setLoading] = useState(false);

  const [fileData, setFileData] = useState([]);

  useEffect(() => {
    setLoading(true);
    const locationUrl = location.pathname;
    const getUrlPath = getUrlLastId(locationUrl);

    onGetDocumentLink(getUrlPath).then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props = {
    // name: "file",
    // action: "//jsonplaceholder.typicode.com/posts/",
    // headers: {
    //   authorization: "authorization-text",
    // },
    // onChange({ fileList }) {
    //
    //   // if (info.file.status !== "uploading") {
    //   //   console.log(info.file, info.fileList);
    //   setFileData(fileList);
    //   // }
    //   // if (info.file.status === "done") {
    //   //   message.success(`${info.file.name} file uploaded successfully`);
    //   // } else if (info.file.status === "error") {
    //   //   message.error(`${info.file.name} file upload failed.`);
    //   // }
    // },
  };

  const onSaveDocumentChecklist = (data) => {
    setLoading(true);
    onGetClientProfile().then((res) => {
      var findItem =
        documentLinkRes &&
        documentLinkRes.items.findIndex((obj) => obj.id == data.id);
      if (findItem > -1) {
        documentLinkRes.items[findItem].uploaded = true;
      }

      const dataObj = {
        subjectId: res.payload.id,
        documentTypeId: 1,
        familyMemberId: 1,
        title: fileData[0].name,
        sizeInKB: 14,
        docuementExtension: "docx",
        docuementBlobUrl: null,
        blobFileName: null,
        isAgent: false,
        isClient: true,
        showAgent: false,
        showClient: true,
        questionnaireId: 0,
        createdBy: "00000000-0000-0000-0000-000000000000",
      };

      const formFileData = new FormData();
      let tempFiles = [];
      for (var i = 0; i < fileData.length; i++) {
        // tempFiles.push(fileData[i].originFileObj);
        formFileData.append("files", fileData[i].originFileObj);
      }

      formFileData.append("BranchId", res.payload && res.payload.branchId);

      onAddMultiDocument(formFileData).then((res) => {
        let newArray = [];
        for (var f = 0; f < res.payload.length; f++) {
          let pdfData = {
            fileName: res.payload[f].fileName,
            fileUrl: res.payload[f].httpUrl,
            blobFileName: res.payload[f].fileUrl,
            size: 0,
          };
          newArray.push(pdfData);
        }
        console.log(i);
        let linkResData = documentLinkRes;
        linkResData.email =
          (linkResData.branch && linkResData.branch.email) || "";

        onAddDocumentChecklist({
          data: JSON.stringify(linkResData),
          files: [{ file: newArray, itemId: data.id }],
        })
          .then((res) => {
            setLoading(false);
            window.location.reload();
          })
          .catch((res) => {
            setLoading(false);
          });
      });
    });
  };

  const columns = [
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (text, record) => <Badge color="yellow" text="Low" />,
    },
    {
      title: "Sn",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Document",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Upload",
      dataIndex: "uploaded",
      key: "uploaded",
      render: (text, record) => (
        <Space className="spinner-height" size="middle">
          {text === true ? (
            <span style={{ paddingLeft: 10 }} className="file-upload-tick">
              <CheckCircleTwoTone twoToneColor="#209f8f" />
            </span>
          ) : (
            <DocumentUploadButton
              props={props}
              checklistData={record}
              onSaveDocumentChecklist={onSaveDocumentChecklist}
              fileData={fileData}
              setFileData={onSetFileData}
            />
          )}
        </Space>
      ),
    },
  ];

  const onSetFileData = (file) => {
    setFileData(file);
  };

  return (
    <div className="mar-top-4rem mb-6rem document-table document-table-checklist ">
      <h3 className="checklist-tbl">Document Checklist</h3>
      <Spin size="large" spinning={loading}>
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          columns={columns}
          dataSource={
            documentLinkRes && documentLinkRes.items && documentLinkRes.items
          }
          pagination={false}
        />
        {/* <Button onClick>Save</Button> */}
      </Spin>
    </div>
  );
};

export default DocumentFormOne;

import React, { useState, useEffect } from "react";
import {
  Avatar,
  Input,
  Form,
  DatePicker,
  Select,
  message,
  Button,
  Spin,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { yyyymmddFormate } from "../../../../Common/reUseFunctions";
import BraftEditor from "braft-editor";
import moment from "moment";

const FamilyPersonalInformation = ({
  memberData,
  getAllCountriesRes,
  onGetVisaTypes,
  canUpdate,
  onUpdateClientProfileMember,
  onUpdatePermissions,
  familyMemberData,
  onGetFamilyMemberDetail,
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [visaTypes, setVisaTypes] = useState([]);
  const [editorState, setEditorState] = useState("");
  const [selectedContry, setSelectedContry] = useState([]);
  const [outputHTML, setOutputHTML] = useState("<p></p>");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    onGetVisaTypes().then((res) => {
      let { items } = res.payload;
      setVisaTypes(items);
    });
  }, []);

  useEffect(() => {
    if (memberData && memberData.notes)
      setEditorState(BraftEditor.createEditorState(memberData.notes));
    const mobilePhone =
      memberData.phones && memberData.phones.length
        ? memberData.phones.filter((el) => el.phoneTypeId === 1)
        : [];
    const landLine =
      memberData.phones && memberData.phones.length
        ? memberData.phones.filter((el) => el.phoneTypeId === 4)
        : [];
    const overseasMobile =
      memberData.phones && memberData.phones.length
        ? memberData.phones.filter((el) => el.phoneTypeId === 3)
        : [];
    const otherMobile =
      memberData.phones && memberData.phones.length
        ? memberData.phones.filter((el) => el.phoneTypeId === 5)
        : [];
    form.setFieldsValue({
      first_name: memberData && memberData.firstName,
      middle_name: memberData && memberData.middleName,
      last_name: memberData && memberData.lastName,
      email:
        memberData && memberData.emails && memberData.emails.length > 0
          ? memberData.emails[0].address
          : "",
      address:
        memberData &&
        memberData.addresses &&
        memberData.addresses[0] &&
        memberData.addresses[0].city,
      mobile_phone: mobilePhone.length ? mobilePhone[0].contact : "",
      overseas_mobile: overseasMobile.length ? overseasMobile[0].contact : "",
      land_line: landLine.length ? landLine[0].contact : "",
      other_contact_information: otherMobile.length
        ? otherMobile[0].contact
        : "",
      current_visa_type:
        memberData && memberData.currentVisaTypeId === 0
          ? undefined
          : memberData && memberData.currentVisaTypeId,
      current_new_zeland_visa_expiry_date:
        memberData &&
        memberData.currentNewZealandVisaExpiry === "1900-01-01T00:00:00+00:00"
          ? ""
          : memberData && new moment(memberData.currentNewZealandVisaExpiry),
      medical_issue_date:
        memberData && memberData.medicals && memberData.medicals.length > 0
          ? memberData.medicals[0].medicalIssueDate ===
            "1900-01-01T00:00:00+00:00"
            ? ""
            : memberData.medicals[0].medicalIssueDate
          : "",
      xray_issue_date:
        memberData && memberData.medicals && memberData.medicals.length > 0
          ? memberData.medicals[0].xrayIssueDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : memberData.medicals[0].xrayIssueDate
          : "",
      passport_number:
        memberData && memberData.passports && memberData.passports.length > 0
          ? memberData.passports[0].passportNo
          : "",
      country_of_issue:
        memberData &&
        memberData.passports &&
        memberData.passports[0] &&
        memberData.passports[0].passportCountry === 0
          ? undefined
          : memberData &&
            memberData.passports[0] &&
            memberData.passports[0].passportCountry,
      passport_issue_date:
        memberData &&
        memberData.passports &&
        memberData.passports.length > 0 &&
        memberData.passports[0].passportIssueDate ===
          "1900-01-01T00:00:00+00:00"
          ? ""
          : memberData &&
            memberData.passports.length &&
            new moment(memberData.passports[0].passportIssueDate),
      passport_expiry_date:
        memberData &&
        memberData.passports &&
        memberData.passports.length > 0 &&
        memberData.passports[0].passportExpiryDate ===
          "1900-01-01T00:00:00+00:00"
          ? ""
          : memberData &&
            memberData.passports.length &&
            new moment(memberData.passports[0].passportExpiryDate),
    });
  }, [memberData]);
  const { t } = useTranslation();
  const getCountry = (id) => {
    if (getAllCountriesRes) {
      let value = getAllCountriesRes.items.filter((el) => el.id === id);
      return value.length
        ? value[0].name === "No Country"
          ? ""
          : value[0].name
        : "";
    } else {
      return "";
    }
  };
  const getVisaName = (id) => {
    let val = visaTypes && visaTypes.filter((el) => el.id === id);
    return val.length ? val[0].visaTypeName : undefined;
  };

  const handleChange = (editorState) => {
    setEditorState(editorState);
    setOutputHTML(editorState.toHTML());
  };

  const handleChangeCountry = (value) => {
    setSelectedContry(value);
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    setLoading(true);
    var data = {
      id: memberData && memberData.id,
      branchId: memberData && memberData.branchId,
      clientNumber: "",
      familyId: memberData && memberData.familyId,
      processingGroupId: 0,
      agentId: memberData && memberData.agentId,
      clientTag: 0,
      firstName: values && values.first_name,
      lastName: values && values.last_name,
      middleName: values && values.middle_name,
      title: "",
      gender: 0,
      dateOfBirth: "2020-12-10T13:21:23.318Z",
      maritalStatus: "",
      dependentChildren: 0,
      notes: outputHTML,
      occupation: "",
      occupationOrganization: "",
      inzUserName: "",
      inzPassword: "",
      imageBlobUrl: "",
      nationalityId: "",
      nationalityCountry: "",
      skypeID: "",
      preferredName: "",
      isSubscribed: true,
      arbitaryJson: "",
      dependentClientIds: "=",
      modifiedBy: memberData && memberData.clientId,
      currentVisaTypeId:
        values && values.current_visa_type
          ? values.current_visa_type
          : memberData.currentVisaTypeId,
      currentNewZealandVisaExpiry:
        values && values.current_new_zeland_visa_expiry_date
          ? values.current_new_zeland_visa_expiry_date.toISOString()
          : "1900-01-01T00:00:00+00:00",
      travelConditionsValidTo: "2020-12-10T13:21:23.318Z",
      visaText: "",
      visaDenied: true,
      deniedText: "",
      clientNumberIZM: "",
      inzFeeDate: "2020-12-10T13:21:23.318Z",
      memberType: memberData && memberData.memberType,
      clientId: memberData && memberData.id,
      nzqaOnlineSubDate: "2020-12-10T13:21:23.318Z",
      nzqaDocumentSubDate: "2020-12-10T13:21:23.318Z",
      nzqaDocumentRetDate: "2020-12-10T13:21:23.318Z",
      visaCountryId: 0,
      visaCountyType: 0,
      age: 0,
      jobSectorId: 0,
      sourceId: 0,
      sourceDescription: "",
      clientSerial: "",
      companyOptional: "",
      dealWorth: "",
      saleDate: "2020-12-10T13:21:23.318Z",
      clientPermission: memberData.clientPermission,
      passports: [],
      clientPhones: [],
      clientMedical: [],
      clientEmails: [],
      policeCertificates: [],
      addresses: [],
    };
    if (values.passport_number && values.passport_number.length)
      data.passports.push({
        id:
          memberData && memberData.passports.length
            ? memberData.passports[0].id
            : 0,
        clientId:
          memberData && memberData.passports.length > 0
            ? memberData.passports[0].clientId
            : memberData.clientId,
        passportNo: values.passport_number,
        passportCountry:
          values && values.country_of_issue ? values.country_of_issue : 0,
        passportIssueDate: values.passport_issue_date
          ? values.passport_issue_date.toISOString()
          : "1900-01-01T00:00:00+00:00",
        passportExpiryDate: values.passport_expiry_date
          ? values.passport_expiry_date.toISOString()
          : "1900-01-01T00:00:00+00:00",
        modifiedBy: memberData && memberData.id,
        passportType: 1,
      });
    if (memberData.phones.length || values.mobile_phone.length)
      data.clientPhones.push({
        id: memberData.phones.length ? memberData.phones[0].id : 0,
        clientId: memberData.id,
        countryCodeId: 168,
        contact: values.mobile_phone ? values.mobile_phone : "",
        phoneTypeId: 1,
        modifiedBy: memberData && memberData.id,
      });
    if (
      (memberData.phones && memberData.phones.length >= 2) ||
      (values.overseas_mobile && values.overseas_mobile.length)
    )
      data.clientPhones.push({
        id:
          memberData.phones && memberData.phones[1] && memberData.phones[1].id
            ? memberData.phones[1].id
            : 0,
        clientId: memberData.id,
        countryCodeId: 168,
        contact: values.secondary_mobile ? values.secondary_mobile : "",
        phoneTypeId: 2,
        modifiedBy: memberData && memberData.id,
      });
    if (
      (memberData.phones && memberData.phones.length >= 3) ||
      (values.overseas_mobile && values.overseas_mobile.length)
    )
      data.clientPhones.push({
        id:
          memberData.phones && memberData.phones[2] && memberData.phones[2].id
            ? memberData.phones[2].id
            : 0,
        clientId: memberData.id,
        countryCodeId: 168,
        contact: values.overseas_mobile ? values.overseas_mobile : "",
        phoneTypeId: 3,
        modifiedBy: memberData && memberData.id,
      });
    if (
      (memberData.phones && memberData.phones.length >= 4) ||
      (values.land_line && values.land_line.length)
    )
      data.clientPhones.push({
        id:
          memberData.phones && memberData.phones[3] && memberData.phones[3].id
            ? memberData.phones[3].id
            : 0,
        clientId: memberData.id,
        countryCodeId: 168,
        contact: values.land_line ? values.land_line : "",
        phoneTypeId: 4,
        modifiedBy: memberData && memberData.id,
      });
    if (
      (memberData.phones && memberData.phones.length >= 5) ||
      (values.other_contact_information &&
        values.other_contact_information.length)
    )
      data.clientPhones.push({
        id:
          memberData.phones && memberData.phones[4] && memberData.phones[4].id
            ? memberData.phones[4].id
            : 0,
        clientId: memberData.id,
        countryCodeId: 168,
        contact: values.other_contact_information
          ? values.other_contact_information
          : "",
        phoneTypeId: 5,
        modifiedBy: memberData && memberData.id,
      });
    if (
      (memberData.emails && memberData.emails.length) ||
      (values.email && values.email.length)
    ) {
      data.clientEmails.push({
        id: memberData.emails.length ? memberData.emails[0].id : 0,
        clientId: memberData.id,
        address: values.email ? values.email : "",
        emailTypeId: 1,
        createdBy: memberData && memberData.id,
      });
    }

    if (
      (memberData.addresses && memberData.addresses.length) ||
      (values.address && values.address.length)
    ) {
      data.addresses.push({
        id:
          memberData && memberData.addresses.length
            ? memberData.addresses[0].id
            : 0,
        clientId: memberData && memberData.id,
        contactPerson: values && values.mobile_phone ? values.mobile_phone : "",
        flat: "",
        building: "",
        streetName: values.address ? values.address : "",
        suburb: "",
        streetNumber: "",
        city: values.address ? values.address : "",
        state: values.state ? values.state : "",
        zip: values.zipCode ? values.zipCode : "",
        country: 0,
        addressTypeId: 1,
        modifiedBy: memberData && memberData.id,
      });
    }

    onUpdateClientProfileMember(data)
      .then(async () => {
        if (memberData) {
          // let { clientPermission } = memberData;
          let clientPermission = JSON.parse(
            sessionStorage.getItem("profilePermission")
          );
          clientPermission.allowUpdate = false;
          // await onUpdatePermissions(clientPermission);
          onGetFamilyMemberDetail().then((res) => {
            debugger;
            let findMember = res.payload.items.find(
              (obj) => obj.id === memberData.id
            );
            familyMemberData(findMember, "show_family_member");
          });

          // await onGetmemberData();
          // await onGetClientProfile();
          setLoading(false);
          message.success("Successfully Updated!");
        }
      })
      .catch(() => setLoading(false));
  };

  return (
    <framgmant>
      <Spin spinning={loading} size="large">
        <Form
          name="register"
          onFinish={onFinish}
          scrollToFirstError
          form={form}
        >
          <div className="container client-info-tab information-area-bg padding-tb">
            <div className="client-information-container">
              <div className="align-center">
                <h2 className="info-heading">{t("PERSONAL INFORMATION")}</h2>
              </div>
              <div className="align-center">
                <Avatar size={70} icon={<UserOutlined />} />
              </div>
              <div className="information-area">
                <ul>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Relation")}:</span>

                    {!canUpdate && (
                      <span className="w40-40">
                        {memberData && memberData.firstName}
                      </span>
                    )}

                    {canUpdate && (
                      <Form.Item
                        className="profile-form"
                        name="first_name"
                        rules={[
                          {
                            required: false,
                            message: "Please input your first name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    )}
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Client's First Name")}:</span>
                    {canUpdate ? (
                      <Form.Item
                        className="profile-form"
                        name="first_name"
                        rules={[
                          {
                            required: false,
                            message: "Please input your first name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      <span className="w40-40">
                        {memberData && memberData.firstName}
                      </span>
                    )}
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Middle Name")}:</span>
                    {canUpdate ? (
                      <Form.Item
                        className="profile-form"
                        name="middle_name"
                        rules={[
                          {
                            required: false,
                            message: "Please input your first name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      <span className="w40-40">
                        {memberData && memberData.middleName}
                      </span>
                    )}
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Last Name")}:</span>
                    {canUpdate ? (
                      <Form.Item
                        className="profile-form"
                        name="last_name"
                        rules={[
                          {
                            required: false,
                            message: "Please input your first name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      <span className="w40-40">
                        {memberData && memberData.lastName}
                      </span>
                    )}
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Email")}:</span>
                    {canUpdate ? (
                      <Form.Item
                        className="profile-form"
                        name="email"
                        rules={[
                          {
                            required: false,
                            message: "Please input your email!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      <span className="w40-40">
                        {memberData &&
                          memberData.firstName &&
                          memberData.emails.map((item, index) => {
                            if (item.emailTypeId === 1) {
                              return <span key={index}>{item.address}</span>;
                            }
                          })}
                      </span>
                    )}
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Address")}:</span>
                    {canUpdate ? (
                      <Form.Item
                        className="profile-form"
                        name="address"
                        rules={[
                          {
                            required: false,
                            message: "Please input your title!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      <span className="w40-40">
                        {memberData &&
                          memberData.addresses &&
                          memberData.addresses.map((item, index) => {
                            return <span key={index}>{item.city}</span>;
                          })}
                      </span>
                    )}
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Mobile Phone")}:</span>
                    {canUpdate ? (
                      <Form.Item
                        className="profile-form"
                        name="mobile_phone"
                        rules={[
                          {
                            required: false,
                            message: "Please input your country!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      <span className="w40-40">
                        {memberData &&
                          memberData.phones &&
                          memberData.phones.map((item) => {
                            return item.phoneTypeId === 1 && item.contact;
                          })}
                      </span>
                    )}
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Land Line")}:</span>
                    {canUpdate ? (
                      <Form.Item
                        className="profile-form"
                        name="land_line"
                        rules={[
                          {
                            required: false,
                            message: "Please input your land line!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      <span className="w40-40">
                        {memberData &&
                          memberData.phones &&
                          memberData.phones.map((item) => {
                            return item.phoneTypeId === 4 && item.contact;
                          })}
                      </span>
                    )}
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">
                      {t("Other Contact Information")}:
                    </span>
                    {canUpdate ? (
                      <Form.Item
                        className="profile-form"
                        name="other_contact_information"
                        rules={[
                          {
                            required: false,
                            message:
                              "Please input your other contact information!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      <span className="w40-40">
                        {memberData &&
                          memberData.phones &&
                          memberData.phones.map((item) => {
                            return item.phoneTypeId === 5 && item.contact;
                          })}
                      </span>
                    )}
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Notes")}:</span>
                    {canUpdate ? (
                      <Form.Item
                        className="profile-form"
                        name="notes"
                        rules={[
                          {
                            required: false,
                            message: "Please input your notes!",
                          },
                        ]}
                      >
                        <div className="editor-wrapper">
                          <BraftEditor
                            value={editorState}
                            onChange={handleChange}
                          />
                        </div>
                        {/* <Input /> */}
                      </Form.Item>
                    ) : (
                      <div
                        className="w40-40"
                        dangerouslySetInnerHTML={{
                          __html: memberData && memberData.notes,
                        }}
                      />
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container client-info-tab information-area-bg mar-top padding-tb">
            <div className="client-information-container">
              <div className="align-center">
                <h2 className="info-heading">{t("PASSPORT DETAIL")}</h2>
              </div>

              <div className="information-area">
                <ul>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Passport Number")}:</span>
                    <div className="w40-40">
                      {canUpdate ? (
                        <Form.Item
                          className="profile-form"
                          name="passport_number"
                          rules={[
                            {
                              required: false,
                              message: "Please input your passport number!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      ) : (
                        <span>
                          {memberData &&
                            memberData.passports &&
                            memberData.passports.map((item, index) => {
                              return <span key={index}>{item.passportNo}</span>;
                            })}
                        </span>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Country Of Issue")}:</span>
                    <div className="w40-40">
                      {canUpdate ? (
                        <Form.Item
                          className="profile-form"
                          name="country_of_issue"
                          rules={[
                            {
                              required: false,
                              message: "Please input your select gender!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            value={selectedContry}
                            onChange={handleChangeCountry}
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                          >
                            {getAllCountriesRes &&
                              getAllCountriesRes.items &&
                              getAllCountriesRes.items.length > 0 &&
                              getAllCountriesRes.items.map((item, index) => (
                                <Option key={index} value={item.id}>
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      ) : (
                        <span className="w40-40">
                          {memberData &&
                            memberData.passports &&
                            memberData.passports &&
                            getCountry(memberData.passports[0].passportCountry)}
                          {/* {memberData &&
                    memberData.passports &&
                    memberData.passports.map((item, index) => {
                      return <span key={index}>{item.passportCountry}</span>;
                    })} */}
                        </span>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Passport Issue Date")}:</span>
                    <div className="w40-40">
                      {!canUpdate && (
                        <Form.Item name="passport_issue_date">
                          <DatePicker disabled format={"DD/MM/YYYY"} />
                        </Form.Item>
                      )}
                      {canUpdate && (
                        <Form.Item
                          name="passport_issue_date"
                          className="profile-form"
                        >
                          <DatePicker format={"DD/MM/YYYY"} />
                        </Form.Item>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">
                      {t("Passport Expiry Date")}:
                    </span>
                    <div className="w40-40">
                      {canUpdate ? (
                        <Form.Item name="passport_expiry_date">
                          <DatePicker
                            className="profile-form"
                            format={"DD/MM/YYYY"}
                          />
                        </Form.Item>
                      ) : (
                        <span>
                          {memberData &&
                            memberData.passports &&
                            memberData.passports.map((item, index) => {
                              if (
                                item.passportType === 1 &&
                                item.passportExpiryDate !==
                                  "1900-01-01T00:00:00+00:00"
                              ) {
                                return (
                                  <span key={index}>
                                    {yyyymmddFormate(item.passportExpiryDate)}
                                  </span>
                                );
                              }
                            })}
                        </span>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container client-info-tab information-area-bg mar-top padding-tb">
            <div className="client-information-container">
              <div className="align-center">
                <h1 className="info-heading">{t("VISA DETAILS (IF KNOWN)")}</h1>
              </div>
              <div className="information-area">
                <ul>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">{t("Current Visa Type")}:</span>
                    <div className="w40-40">
                      {canUpdate ? (
                        <Form.Item
                          className="profile-form"
                          name="current_visa_type"
                          rules={[
                            {
                              required: false,
                              message: "Please input your current visa type!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            // defaultValue={
                            //   memberData && memberData.currentVisaTypeId === 0
                            //     ? undefined
                            //     : memberData && memberData.currentVisaTypeId
                            // }
                            // value={
                            //   memberData && memberData.currentVisaTypeId === 0
                            //     ? undefined
                            //     : memberData && memberData.currentVisaTypeId
                            // }
                          >
                            {visaTypes &&
                              visaTypes.map((item, index) => (
                                <Option key={index} value={item.id}>
                                  {item.visaTypeName}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      ) : (
                        <span className="w40-40">
                          {memberData &&
                            getVisaName(memberData.currentVisaTypeId)}
                        </span>
                      )}
                    </div>
                  </li>
                  <li className="d-flex client-information-setting">
                    <span className="w60-per">
                      {t("Current Visa Expiry Date")}:
                    </span>
                    <div className="w40-40">
                      {canUpdate ? (
                        <Form.Item
                          name="current_new_zeland_visa_expiry_date"
                          className="profile-form"
                        >
                          <DatePicker format={"DD/MM/YYYY"} />
                        </Form.Item>
                      ) : (
                        <span>
                          {memberData &&
                            yyyymmddFormate(
                              memberData
                                ? memberData.currentNewZealandVisaExpiry ===
                                  "1900-01-01T00:00:00+00:00"
                                  ? ""
                                  : memberData.currentNewZealandVisaExpiry
                                : ""
                            )}
                        </span>
                      )}
                    </div>
                  </li>
                  {/* <li className="d-flex client-information-setting">
                  <span className="w60-per">{t("Medical Issue Date")}:</span>
                  <div className="w40-40">
                    {canUpdate ? (
                      <Form.Item
                        name="medical_issue_date"
                        className="profile-form"
                      >
                        <DatePicker format={"DD/MM/YYYY"} />
                      </Form.Item>
                    ) : (
                      <span>
                        {memberData &&
                          memberData.medicals &&
                          memberData.medicals.map((item, index) => {
                            return (
                              <span key={index}>
                                {yyyymmddFormate(item.medicalIssueDate)}
                              </span>
                            );
                          })}
                      </span>
                    )}
                  </div>
                </li> */}
                  {/* <li className="d-flex client-information-setting">
                  <span className="w60-per">{t("Xray Date")}:</span>
                  <div className="w40-40">
                    {canUpdate ? (
                      <Form.Item
                        name="xray_issue_date"
                        className="profile-form"
                      >
                        <DatePicker format={"DD/MM/YYYY"} />
                      </Form.Item>
                    ) : (
                      <span>
                        {memberData &&
                          memberData.medicals &&
                          memberData.medicals.map((item, index) => {
                            return (
                              <span key={index}>
                                {yyyymmddFormate(item.xrayIssueDate)}
                              </span>
                            );
                          })}
                      </span>
                    )}
                  </div>
                </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="mar-top d-flex flex-end submit-access-btn">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button mr-24"
                disabled={!canUpdate}
              >
                {t("Submit")}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </framgmant>
  );
};

export default FamilyPersonalInformation;
